import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Team from './Team';
import Score from './Score';

const Game = (props) => {
    const { game } = props;
    if (!game) return (<p>No game found.</p>);

    function getScore(game, field) {
        switch (game.league) {
            case "MLB":
                return game[`${field}_batter_totals`].runs;
            case "NBA":
                return game[`${field}_totals`].points;
            default:
                return 0;
        }
    }
 
    let homeScore = getScore(game, "home"),
        awayScore = getScore(game, "away"),
        gameSegments = game.league === "MLB" ? 9 : 4,
        isHomeWinner = homeScore > awayScore,
        isAwayWinner = awayScore > homeScore,
        homeWrapUp = [{
            "title": game.league === "MLB" ? "R" : "T",
            "value": homeScore
        }],
        awayWrapUp = [{
            "title": game.league === "MLB" ? "R" : "T",
            "value": awayScore
        }];
    
        if (game.league === "MLB") {
            homeWrapUp.push({
                "title": "H",
                "value": game.home_batter_totals.hits
            });
            awayWrapUp.push({
                "title": "H",
                "value": game.away_batter_totals.hits
            });
            homeWrapUp.push({
                "title": "E",
                "value": game.home_errors
            });
            awayWrapUp.push({
                "title": "E",
                "value": game.away_errors
            });
        }
        else if (game.league === "NBA"){
            homeWrapUp.push({
                "title": "A",
                "value": game.home_totals.assists
            });
            awayWrapUp.push({
                "title": "A",
                "value": game.away_totals.assists
            });
            homeWrapUp.push({
                "title": "TP",
                "value": game.home_totals.three_point_field_goals_made
            });
            awayWrapUp.push({
                "title": "TP",
                "value": game.away_totals.three_point_field_goals_made
            });
        }
    

    return (
        <div>
            <div className='d-flex align-items-center flex-wrap mb-4 mb-lg-0'>
                <div className='col-lg-5 col-12 display-5 px-2 text-lg-end order-1'>
                    <div className="d-flex align-items-center">
                        <div className={`order-2 order-lg-0 display-1 ms-auto ms-lg-0 me-lg-auto ${isAwayWinner ? "text-white" : ""}`}>
                            {awayScore}
                        </div>
                        <div className={`order-1 ${isAwayWinner ? "text-white" : ""}`}>
                            {game.away_team.first_name} <br />
                            {game.away_team.last_name}
                        </div>
                        <div className='col-2 px-2 order-lg-2 order-0'>
                            <img
                                className="img-fluid"
                                src={`/images/logos/${game.away_team.team_id}.svg`}
                                alt={game.away_team.full_name}></img>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-lg-2 flex-shrink display-5 text-center px-4 order-2'>
                    @
                </div>
                <div className='col-lg-5 col-12 display-5 px-2 text-start  order-4 order-lg-3'>
                    <div className="d-flex align-items-center">
                        <div className='col-2 px-2 order-0 order-lg-0'>
                            <img
                                className="img-fluid"
                                src={`/images/logos/${game.home_team.team_id}.svg`}
                                alt={game.home_team.full_name}></img>
                        </div>
                        <div className={`order-lg-1 order-1 ${isHomeWinner ? "text-white" : ""}`}>
                            {game.home_team.first_name} <br />
                            {game.home_team.last_name}
                        </div>
                        <div className={`order-2 display-1 ms-auto ${isHomeWinner ? "text-white" : ""}`}>
                            {homeScore}
                        </div>
                    </div>
                </div>
            </div>
            <Row>
                <Col>
                    <Score
                        homeTeam={game.home_team}
                        awayTeam={game.away_team}
                        eventDate={game.event_information.start_date_time}
                        homeScores={game.home_period_scores}
                        awayScores={game.away_period_scores}
                        homeWrapUp={homeWrapUp}
                        awayWrapUp={awayWrapUp}
                        totalSegments={gameSegments}></Score>
                </Col>
            </Row>
            <Row className='flex-wrap'>
                <Col className='col-lg-6 col-12'>
                    <Team
                        team={game.away_team}
                        batters={game.away_batters}></Team>
                </Col>
                <Col className='col-lg-6 col-12'>
                    <Team
                        team={game.home_team}
                        batters={game.home_batters}></Team>
                </Col>
            </Row>
        </div>

    );
}
export default Game;