import React from "react";
import './css/App.css';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ThemeProvider from './components/ThemeProvider';
import { Route, Routes, useNavigate } from "react-router-dom";

import MasterPage from "./pages/MasterPage";
import HomePage from "./pages/HomePage";

function App() {
  const navigate = useNavigate();
  function selectNavKey(key) {
    navigate(`/${key}`);
  }

  return (
    <ThemeProvider>
      <header className="App-header navbar navbar-expand-lg bd-navbar sticky-top px-2">
          <Navbar>
            <Navbar.Brand href="/">Barstool</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className="me-auto"
                onSelect={selectNavKey}
              >
                <Nav.Link eventKey="MLB">MLB</Nav.Link>
                <Nav.Link eventKey="NBA">NBA</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
      </header>
      <Routes>
        <Route path="*" element={<HomePage />}></Route>
        <Route path=":leagueKey" element={<MasterPage />}></Route>
      </Routes>
    </ThemeProvider>
  );
}

export default App;
