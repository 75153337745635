import React, { useState, useEffect } from "react";
import League from '../components/League';
import ComponentLoading from '../components/ComponentLoading';
import { useParams } from "react-router-dom"

const MasterPage = () => {
    const { leagueKey } = useParams();
    console.log(leagueKey)
    const LeagueLoading = ComponentLoading(League);
  
    const [loading, setLoadingState] = useState(false),
        [league, setLeagueState] = useState(null),
        [game, setGameState] = useState(null);

    useEffect(() => {
        const leagueData = {
            "MLB": {
                "label": "MLB",
                "url": "/api/games/mlb/latest"
            },
            "NBA": {
                "label": "NBA",
                "url": "/api/games/nba/latest"
            }
        };

        var selectedLeague = leagueData[leagueKey];

        if (selectedLeague && (!league || league.label !== selectedLeague.label)) {
            setLeagueState(selectedLeague);
        }
    }, [leagueKey, league]);

    useEffect(() => {
        if (league) {
            document.title = `${league.label} - Barstool Box Scores`;

            setLoadingState(true);
            function getLeagueData(league) {
                if (league &&
                    league.url) {
                    fetch(league.url, {
                        crossDomain: true
                    }).then((res) => res.json())
                        .then((data) => {
                            setLoadingState(false);
                            setGameState(data);
                        });
                }
            }

            getLeagueData(league);
        }
    }, [league]);

    if (!league) {
        return (<p className="display-3 text-center m-4">League not found</p>);
    }

    return (
        <LeagueLoading isLoading={loading} league={league} game={game}></LeagueLoading>
    );
};
export default MasterPage;