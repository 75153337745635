import React from 'react';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Game from './Game';


const League = (props) => {
    const { league, game } = props;

    if (!league || league.length === 0) return <p className="display-3 text-center m-4">No data, sorry</p>;
    return (
        <Container fluid className='my-2'>
            <Stack gap={3}>
                <h2 className='list-head'>{league.label.toUpperCase()}</h2>
                <Game
                    game={game}
                ></Game>
            </Stack>
        </Container>
    );
};
export default League;