import React from 'react';
import Table from 'react-bootstrap/Table';

const BatterList = (props) => {
    const { batters } = props;

    if (!batters || batters.length === 0) return "";
    return (
        <div>
            <h4>HITS</h4>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>HITTER</th>
                        <th className="text-center">AB</th>
                        <th className="text-center">R</th>
                        <th className="text-center">H</th>
                        <th className="text-center">AVG</th>
                    </tr>
                </thead>
                <tbody>
                    {batters.map((batter, i) => {
                        return (
                            <tr key={`batter_${i}`} className='batter'>
                                <td>{batter.display_name}
                                    <span className="badge rounded-pill text-bg-primary float-end">
                                        {batter.position}
                                    </span>
                                    <br />
                                    <small>{batter.batting_highlights}</small>
                                </td>
                                <td className="text-center">{batter.at_bats}</td>
                                <td className="text-center">{batter.runs}</td>
                                <td className="text-center">{batter.hits}</td>
                                <td className="text-center">{batter.avg}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};
export default BatterList;