import React from 'react';
import Stack from 'react-bootstrap/Stack';
import BatterList from './BatterList';

const Team = (props) => {
    const { team, batters } = props;

    if (!team || team.length === 0) return "";
    return (
        <div>
            <h3>{team.first_name} {team.last_name}</h3>
            <Stack>
            {batters ? (
                <BatterList batters={batters} ></BatterList>
                ) : ""}
                  </Stack>
        </div>
         
    );
};
export default Team;