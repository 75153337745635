import React from 'react';
import Table from 'react-bootstrap/Table';

const Score = (props) => {
    const { homeTeam, awayTeam, eventDate, homeScores, awayScores, homeWrapUp, awayWrapUp, totalSegments } = props;
    let segments = [...Array(totalSegments).keys()]

    function buildScoreCell(score, i) {
        return (
            <td  key={`score_${i}`} className="text-center">{score ? score : score === 0 ? score : "-"}</td>
        );
    }
    if (!homeTeam || !awayTeam || !homeScores || !awayScores) return "";
    return (
        <div>
            <h4 className="d-flex">Score
                {eventDate ? (
                    <small className="ms-auto">
                        {new Date(eventDate).toLocaleDateString('en-us', { weekday: "long", year: "numeric", month: "short", day: "numeric" })}
                    </small>) : ""}
            </h4>
            <div className='d-flex flex-wrap'>

                <div className="col-lg-9 col-12 pe-lg-2">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th className="px-2"></th>
                                {segments.map((i) => {
                                    return (<th key={`segmentHeader_${i}`} className="text-center px-2">{i + 1}</th>)
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{awayTeam.abbreviation}</td>
                                {segments.map((i) => {
                                    return buildScoreCell(awayScores[i], i);
                                })}
                            </tr>
                            <tr>
                                <td>{homeTeam.abbreviation}</td>
                                {segments.map((i) => {
                                    return buildScoreCell(homeScores[i], i);
                                })}
                            </tr>
                        </tbody>
                    </Table>
                </div>

                <div className="col-lg-3 col-12 ps-lg-2">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                {homeWrapUp.map((w, i) => {
                                    return (
                                        <th key={`homeWrapUpHeader${i}`} className="text-center px-2">{w.title}</th>
                                    )
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {awayWrapUp.map((w, i) => {
                                    return (
                                        <td key={`awayWrapUp${i}`} className="text-center px-2">{w.value}</td>
                                    )
                                })}
                            </tr>
                            <tr>
                                {homeWrapUp.map((w, i) => {
                                    return (
                                        <td key={`homeWrapUp_${i}`} className="text-center px-2">{w.value}</td>
                                    )
                                })}
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );
};
export default Score;