import React from 'react';

function ComponentLoading(Component) {
  return function ComponentLoading({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return (
      <p className='text-center display-5 m-4'>
        Hold on, fetching data may take some time :)
      </p>
    );
  };
}
export default ComponentLoading;